<template>
  <div
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="document__row"
    :draggable="disabledView ? false: draggable"
    @dragstart="startDrag($event, document)"
    @mouseup="mouseUp"
  >
    <div class="document__select">
      <v-checkbox
        tabindex="-1"
        dense
        class="pa-0 ml-1"
        hide-details
        color="#5CB7B1"
        :value="selectedDocs.includes(item.id)"
        :disabled="document.bd_44"
        @change="updateSelected"
      />
    </div>

    <div class="document__sort">
      <div
        class="allow-drag"
        @mousedown="mouseDown($event)"
      >
        {{ document.sort_index }}
      </div>
    </div>

    <div class="document__code">
      <v-text-field
        v-show="customFieldChecker(doc_types, document.doc_code, 'code')"
        ref="doc_code"
        v-model="document.doc_code"
        v-mask="'XXXXX'"
        outlined
        dense
        hide-details="auto"
        :background-color="disabledFieldIfBd44 ? 'grey lighten-2': 'white'"
        :disabled="disabledFieldIfBd44"
      />
      <v-tooltip
        bottom
        max-width="300px"
        :disabled="!document.doc_code || hideTooltips"
      >
        <template #activator="{ on }">
          <v-autocomplete
            v-show="!customFieldChecker(doc_types, document.doc_code, 'code')"
            ref="doc_code__custom"
            v-model="document.doc_code"
            auto-select-first
            :items="doc_types"
            item-text="code"
            :filter="filterBySearchField"
            item-value="code"
            outlined
            dense
            hide-details="auto"
            :background-color="disabledFieldIfBd44 ? 'grey lighten-2': 'white'"
            :disabled="disabledFieldIfBd44"
            v-on="on"
          />
        </template>
        <span>{{ docName }}</span>
      </v-tooltip>

      <v-btn
        tabindex="-1"
        min-width="36px"
        max-width="36px"
        color="#5CB7B1"
        class="ml-1 elevation-0"
        :disabled="disabledFieldIfBd44"
        @click="showDocuments"
      >
        <v-icon> mdi-format-list-bulleted </v-icon>
      </v-btn>
    </div>

    <div class="document__number">
      <v-text-field
        v-show="document.doc_code !== '03031'"
        ref="doc_number"
        v-model="document.doc_number"
        outlined
        dense
        hide-details="auto"
        :background-color="disabledFieldIfBd44 ? 'grey lighten-2': 'white'"
        :disabled="disabledFieldIfBd44"
      />
      <v-text-field
        v-show="document.doc_code === '03031'"
        ref="doc_number__custom"
        :disabled="document.deal_no_required || disabledFieldIfBd44"
        :value="document.doc_number"
        outlined
        dense
        hide-details="auto"
        :background-color="disabledFieldIfBd44 ? 'grey lighten-2': 'white'"
        @input="useDocumentMask"
      />
    </div>

    <div class="document__date">
      <custom-date-picker
        ref="doc_date"
        v-model="document.doc_date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
        :background-color="disabledFieldIfBd44 ? 'grey lighten-2': 'white'"
        :disabled="disabledFieldIfBd44"
      />
    </div>

    <div class="document__range">
      <v-text-field
        ref="ware_range"
        v-model="range"
        placeholder="Все товары"
        outlined
        dense
        hide-details="auto"
        :background-color="disabledFieldIfBd44 ? 'grey lighten-2': 'white'"
        :disabled="disabledFieldIfBd44"
        @change="onRangeChange"
      />
    </div>
    <div
      v-if="disabledView"
      class="document__bd44"
    >
      <v-icon v-if="document.bd_44">
        mdi-database-check
      </v-icon>
    </div>

    <div
      v-show="document.doc_code === '02024'"
      class="document__addition"
    >
      <div class="d-flex">
        <v-text-field
          v-model="document.tir_page_number"
          placeholder="Номер листа"
          outlined
          dense
          hide-details="auto"
          class="pr-1"
          :background-color="disabledView ? 'grey lighten-2': 'white'"
          :disabled="disabledView"
        />
        <v-text-field
          v-model="document.tir_owner_inn"
          placeholder="ИН держателя"
          outlined
          dense
          hide-details="auto"
          :background-color="disabledView ? 'grey lighten-2': 'white'"
          :disabled="disabledView"
          class="pl-1"
        />
      </div>
    </div>
    <div
      v-show="document.doc_code === '01401' || document.doc_code === '01402'"
      class="document__addition"
    >
      <v-autocomplete
        v-model="document.customs_code"
        auto-select-first
        placeholder="Код таможенного органа"
        outlined
        dense
        hide-details="auto"
        :background-color="disabledView ? 'grey lighten-2': 'white'"
        :disabled="disabledView"
        item-text="search"
        item-value="code"
        :items="customsPoints"
      />
    </div>

    <v-btn
      text
      :disabled="disabledView"
      tabindex="-1"
      max-width="36px"
      min-width="36px"
      class="elevation-0 ml-1 document-item-delete"
      @click="deleteDocument"
    >
      <v-icon>mdi-delete</v-icon>
    </v-btn>

    <v-menu
      :disabled="disabledView"
      offset-x
    >
      <template #activator="{ on }">
        <v-btn
          text
          :disabled="disabledView"
          tabindex="-1"
          max-width="36px"
          min-width="36px"
          class="elevation-0 document-item-menu"
          v-on="on"
        >
          <v-icon>mdi-tune</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          disabled
        >
          Привязать документ к резиденту
        </v-list-item>
        <v-list-item
          disabled
        >
          Привязать документ к нерезиденту
        </v-list-item>
        <v-list-item
          v-if="!customFieldChecker(doc_types, document.doc_code, 'code')"
          @click="document.doc_code = ''"
        >
          Произвольное значение
        </v-list-item>
        <v-list-item
          v-else
          @click="document.doc_code = null"
        >
          Значение из справочника
        </v-list-item>
        <v-list-item
          @click="openGovRegistrationsCatalog"
        >
          <v-icon>mdi-magnify</v-icon> Поиск в реестре СГР
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      v-if="index === presentedDocuments.length - 1"
      text
      class="document-add elevation-0"
      min-width="36px"
      max-width="36px"
      @click="addNewDoc"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <component
      :is="activeComponent"
      :show.sync="show"
      :item="document"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { deleteNulls } from "@/helpers/inputs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import {
  isGoodsRangeValid,
  isCorrectStartEnd,
  trimRange,
} from "@/helpers/inputs";
import { showCatalog } from "@/events/statistics";
import { documentTypeSelected } from "@/events/statistics";
import CustomDatePicker from "@/components/shared/custom-date-picker";
import { DEAL_REGISTRATION_NOT_REQUIRED } from "@/constants";
import highlightFieldsChild from "@/mixins/control/highlight-fields-child";
export default {
  components: {
    CustomDatePicker,
  },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, highlightFieldsChild],
  props: {
    index: {
      required: true,
      type: Number,
    },
    item: {
      required: true,
      type: Object,
    },
    draggable: {
      required: true,
      type: Boolean,
    },
    forSingleItem: {
      required: true,
      type: Boolean,
    },
    hideTooltips: {
      required: true,
      type: Boolean,
    },
    selectedDocs: {
      required: true,
      type: Array,
    },
    presentedDocuments: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    activeComponent:false,
    show:false,
    selected: null,
    mousePressed: false,
    isIntersecting: false,
    parent: null,
    block_id: null,
    menu: false,
    document: {
      id: "",
      ware_shipment_id: "",
      doc_code: "",
      doc_number: "",
      doc_date: "",
      customs_code: "",
      ware_number_from_dt: "",
      tir_page_number: "",
      tir_owner_inn: "",
      ware_range: "",
      sort_index: "",
      bd_44: false,
      doc_type_bd_44: "",
    },
    range: "",
    noRegistration: true,
    key: "",
    noRegistrationText: DEAL_REGISTRATION_NOT_REQUIRED,
  }),

  computed: {
    ...mapGetters({
      doc_types: "catalogs/getNsiTypesOfDocument",
      customsPoints: "catalogs/getNsiCustomsPoints",
      disabledView: "transit/getVisibility"
    }),
    docName() {
      const { name } =
        this.doc_types.find((i) => i.code === this.document.doc_code) || {};
      return name || "";
    },
    disabledFieldIfBd44() {
      return !!(this.disabledView && this.docTypeBd44);
    },
    docTypeBd44() {
      if (this.document.doc_type_bd_44 === 2) {
        if (this.document.bd_44) return true;
        else return false
      } return true
    },
  },
  watch: {
    show(nv){
      if(nv === false) this.activeComponent = null
    },
    "document.doc_code": {
      handler(nv) {
        if (
          (nv === "03031" &&
            !this.checkDocumentMask(this.document.doc_number) &&
            !this.document.deal_no_required) ||
          (nv !== "03031" &&
            this.document.doc_number === DEAL_REGISTRATION_NOT_REQUIRED) //Если был 03031 то очищаем поле в случае "не трубует регистрации"
        ) {
          this.document.doc_number = "";
          this.document.deal_no_required = null;
        }
      },
    },
    range(nv) {
      this.validateRange(nv.replace(".", ","));
    },
    item: {
      handler(newVal) {
        this.block_id = `transit-present-document-${this.item.id}`;
        for (let key in newVal) {
          if (key in this.document) {
            this.document[key] = newVal[key];
          }
        }
        this.range = newVal?.ware_range ?? "";
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    documentTypeSelected.subscribe(this.checkAndUpdateDocType);
    const collection = [...document.getElementsByClassName("drop-zone")];
    const [parent] = collection;
    this.parent = parent;
  },
  beforeDestroy() {
    documentTypeSelected.unsubscribe();
  },
  methods: {
    openGovRegistrationsCatalog(){
      showCatalog.trigger({
        type: "government_registrations",
        search: this.document.doc_number,
      })
    },
    checkDocumentMask(value) {
      const regex = /([0-9]{6}\/[0-9]{6}\/[0-9]{5})/g;
      return new RegExp(regex).test(value);
    },
    useDocumentMask(value) {
      // mask = ######/######/#####
      if (this.document.doc_code === "03031") {
        const symbols = value.split("");
        const errors = symbols.filter(
          (i) => i !== "/" && Number.isNaN(Number.parseInt(i))
        );
        if (errors.length || value.length > 19) {
          const { doc_number } = this.document;
          this.document.doc_number = ""; // Чтобы значение в v-text-field обновилось
          this.$nextTick(() => {
            this.document.doc_number = doc_number;
          });
        } else {
          if (
            value.length > this.document.doc_number?.length &&
            (value.length === 7 || value.length === 14)
          ) {
            const symbols = value.split("").reverse();
            const [end, ...start] = symbols;
            this.document.doc_number = [...start.reverse(), "/", end].join("");
          } else {
            this.document.doc_number = value;
          }
        }
      } else {
        this.document.doc_number = value;
      }
    },
    onRangeChange(value) {
      this.range = trimRange(value);
    },
    addNewDoc() {
      this.$emit("addNewDoc");
    },
    customFieldChecker(items, field, param) {
      if (field === null) {
        return false;
      } else if (field === "") {
        return true;
      } else {
        let positiveArr = items.filter(function (i) {
          return i[param] === field;
        });
        return !(positiveArr.length);
      }
    },
    updateSelected() {
      this.$emit("select", this.item.id);
    },
    validateRange(nv) {
      const result = isGoodsRangeValid(nv);
      if (result !== false) {
        this.document.ware_range = result;
        this.range = result;
      } else {
        this.$nextTick(() => {
          this.range = this.document.ware_range;
        });
      }
    },
    startDrag(evt, document) {
      if (this.hasChanges && this.fields_been_visited) {
        return this.$snackbar({
          text: "Сохраните изменения перед сортировкой.",
          color: "orange",
          top: false,
          right: false,
        });
      }
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("document", document.id);
      // console.log('get', evt.dataTransfer.getData("document"))
    },
    mouseUp() {
      this.$emit("update:draggable", false);
    },
    mouseDown(evt) {
      // Перетаскивание только в режиме просмотра всех товаров
      if (
        [...evt.target.classList].includes("allow-drag") &&
        !this.forSingleItem
      ) {
        this.$emit("update:draggable", true);
      }
    },
    deleteNulls,
    deleteDocument() {
      const { id } = this.item;
      const index = this.index;
      this.$emit("delete", {
        id,
        index,
      });
    },
    uploadData() {
      if (!isCorrectStartEnd(this.document.ware_range)) {
        this.processing = false; //  Если вышли из блока когда не верный диапазон то надо разрешить отправку повторно
        return this.$snackbar({
          text: "Неверное начало и конец диапазона товаров",
          color: "red",
          top: false,
          right: false,
        });
      }
      const index = this.index;
      const payload = convertEmptyStringsToNull(this.document);
      return this.$store.dispatch("transit/updateShipmentDocument", {
        index,
        payload,
      }).then((res) => {
        if(this.document.doc_code === "09024") this.showVehicleUpdateModal()
        return res
      });
    },
    showVehicleUpdateModal(){
      this.activeComponent = () => import('@/components/transit/documents/update-vehicle-document.vue')
      this.show = true
    },
    showDocuments() {
      showCatalog.trigger({
        type: "doctypes",
        id: this.document.id,
        search: this.document.doc_code,
      });
    },
    checkAndUpdateDocType({ id, code }) {
      if (this.document.id === id) {
        this.document.doc_code = code;
        this.fieldsBeenVisitedByUser();
      }
    },
  },
};
</script>
<style scoped>
.document__row {
  display: flex;
  align-items: center;
}
.document__select {
  display: flex;
  justify-content: center;
  align-items: center;
}
.document__sort {
  width: 60px !important;
  padding: 0 10px 0 0;
}

.document__code {
  width: 180px !important;
  display: flex;
  align-items: center;
  padding: 0 10px 0 0;
}
.document__number {
  width: 300px !important;
  padding: 0 10px 0 0;
}
.document__date {
  width: 160px !important;
}
.document__range {
  width: 250px !important;
  padding: 0 10px;
}
.document__bd44{
  min-width: 24px;
}
.document__addition {
  width: 300px !important;
}
.h-full {
  height: 100%;
}

.document-row:last-child {
  padding: 0 10px 4px 0 !important;
}
.allow-drag {
  cursor: pointer !important;
}
</style>
